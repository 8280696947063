import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import HttpRequest from "@/components/httpRequest";
import useAppInfo from "@/modules/app/use-app-info";

const useCompanyGroupListUnit = create(
  immer((set, get) => ({
    clientDataVersion: 0,
    companyGroups: [],
    companyGroupsIndexed: undefined,
    companyGroupOptions: [],
    pageStatus: "loading",

    async initialize() {
      try {
        await get().loadCompanyGroups();
        set((state) => {
          state.pageStatus = "ready";
        });
      } catch (error) {
        set((state) => {
          state.pageStatus = "unspecified";
        });
        console.error("93a81fd450af42259b27ff0cf180a164", error);
      }
    },

    async loadCompanyGroups() {
      const { clientDataVersion } = get();
      const { companyGroupList, dbDataVersion, notModified } =
        await getCompanyGroups(clientDataVersion);

      if (!notModified) {
        const companyGroupsIndexed = getGroupArray(companyGroupList);

        const companyGroupOptions = getCompanyGroupOptions(companyGroupList);

        set((state) => {
          state.companyGroups = companyGroupList;
          state.companyGroupsIndexed = companyGroupsIndexed;
          state.companyGroupOptions = companyGroupOptions;
          state.clientDataVersion = dbDataVersion;
        });
      }
    },
  })),
);

function getCompanyGroups(clientDataVersion) {
  const { appApiAddress, appToken } = useAppInfo.getState();

  return new HttpRequest(
    appApiAddress,
    `/metot/get-company-group-list?clientDataVersion=${clientDataVersion}`,
  )
    .addHeader("authorization", appToken)
    .get();
}

export function getGroupArray(groupList) {
  const indexed = new Array();
  groupList.forEach((g) => (indexed[g.groupId] = g));
  return indexed;
}

function getCompanyGroupOptions(companyGroupList) {
  return companyGroupList.map((g) => {
    return {
      id: g.groupId,
      label: g.groupName,
      color: g.color,
      color2: g.color2,
    };
  });
}

export default useCompanyGroupListUnit;
