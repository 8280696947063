import HttpRequest from "@/components/httpRequest";
import { validateEMail } from "@/components/utils";
import _isEmpty from "lodash/isEmpty";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import useAppInfo, { showErrorMessage } from "../../app/use-app-info";
import useCompanyGroupListUnit from "../../company-group/company-group-list-unit/use-company-group-list-unit";
import _uniqueId from "lodash/uniqueId";
import useCurrencyListUnit from "../../currency/use-currency-list-unit";

const initialState = {
  reloadKey: 0,
  pageStatus: undefined,
  editorMode: undefined,
  company: {},
  groups: undefined,
  extraInfo: undefined,
  errors: {},
  validationWasMade: false,
};

const useCompanyUnit = create(
  immer((set, get) => ({
    ...initialState,

    changeCompanyInfo(values) {
      set((state) => {
        state.company = { ...state.company, ...values };
      });

      const { validate, validationWasMade } = get();
      if (validationWasMade) validate();
    },

    changeState(values) {
      set((state) => {
        return { ...state, ...values };
      });
    },

    validate() {
      const errors = findErrors(get().company);
      set((state) => {
        state.errors = errors;
        state.validationWasMade = true;
      });
      return _isEmpty(errors);
    },

    async initialize(companyId) {
      try {
        set((state) => {
          state.pageStatus = "loading";
          state.editorMode = isNaN(companyId);
        });

        await Promise.all([
          useCompanyGroupListUnit.getState().loadCompanyGroups(),
          useCurrencyListUnit.getState().loadCurrencies(),
        ]);

        if (companyId) await loadCompany(companyId);
        else loadNew();

        set((state) => {
          state.pageStatus = "ready";
        });
      } catch (error) {
        set((state) => {
          state.pageStatus = "unspecified";
        });
        console.error("fc3568596a8b4d96a951f191c245562b", error);
      }

      function loadNew() {
        const companyGroups = useCompanyGroupListUnit.getState().companyGroups;
        const activeCurrencies =
          useCurrencyListUnit.getState().activeCurrencies;

        const groups = companyGroups
          .filter((g) => g.defaultValue)
          .map((g) => g.groupId);

        const balances = activeCurrencies.map((c) => ({
          currencyId: c.currencyId,
          currencyName: c.currencyName,
          balances: 0,
        }));

        set((state) => {
          state.company = {};
          state.groups = groups;
          state.extraInfo = generateExtraInfoRows(3);
          state.balances = balances;
          state.errors = {};
          state.validationWasMade = false;
          state.originals = undefined;
        });
      }

      async function loadCompany(companyId) {
        const { company, groups, extraInfo, balances } =
          await fetchCompany(companyId);
        set((state) => {
          state.company = company;
          state.groups = groups;
          state.extraInfo = extraInfo.map((info) => ({
            ...info,
            saved: true,
            originalRowNumber: info.rowNumber,
          }));
          state.originals = { company, groups: JSON.stringify(groups) };
          state.balances = balances;
        });
      }
    },

    reset() {
      set((state) => ({ ...state, ...initialState }));
    },

    reload() {
      set((state) => {
        state.editorMode = false;
        state.reloadKey++;
      });
    },

    setEditorMode(value) {
      set((state) => {
        state.editorMode = value;
      });
    },

    async createCompany() {
      try {
        set((state) => {
          state.pageStatus = "running";
        });

        const { company, groups, extraInfo, balances } = get();

        const newExtraInfo = extraInfo
          .filter((info) => info.label || info.value)
          .map((info, rowNumber) => ({
            ...info,
            infoId: undefined,
            rowNumber,
          }));

        const postData = {
          ...company,
          groups,
          extraInfo: newExtraInfo,
          openingBalances: balances,
        };
        await postCompany(postData);
        return true;
      } catch (error) {
        console.error("68ab920da70b49a8b79ca914b4b64130", error);
        showErrorMessage("Hata oluştu");
      } finally {
        set((state) => {
          state.pageStatus = "ready";
        });
      }
    },

    async updateCompany() {
      try {
        set((state) => {
          state.pageStatus = "running";
        });

        const { originals, company, groups, extraInfo } = get();
        const changes = [];
        checkChange("companyName");
        checkChange("phoneNumber");
        checkChange("eMail");
        checkChange("address");
        checkChange("taxOffice");
        checkChange("taxNumber");

        if (originals.groups !== JSON.stringify(groups)) changes.push("groups");
        const newExtraInfo = prepareExtraInfo(extraInfo);
        if (isExtraInfoChanged(newExtraInfo)) changes.push("extraInfo");

        const putData = {
          ...company,
          groups,
          extraInfo: changes.includes("extraInfo") ? newExtraInfo : undefined,
          changes,
        };
        if (changes.length === 0) return true;

        await putCompany(putData);
        return true;
        /************/
        function checkChange(fieldName) {
          if (originals.company[fieldName] !== company[fieldName]) {
            changes.push(fieldName);
          }
        }

        function prepareExtraInfo(extraInfo) {
          let rowNumber = 0;

          return extraInfo
            .filter((info) => info.label || info.value || info.saved)
            .map((info) => {
              const deleted = info.deleted || (!info.label && !info.value);
              const newRowNumber = deleted ? 0 : rowNumber++;

              const rowNumberChanged =
                info.saved &&
                !info.changed &&
                !deleted &&
                info.originalRowNumber !== newRowNumber;

              return {
                infoId: info.infoId,
                label: info.label,
                value: info.value,
                new: !info.saved,
                changed: info.changed,
                deleted,
                rowNumber: newRowNumber,
                rowNumberChanged,
              };
            });
        }

        function isExtraInfoChanged(extraInfo) {
          return extraInfo.some(
            (info) =>
              info.changed || info.deleted || info.new || info.rowNumberChanged,
          );
        }
        /************/
      } catch (error) {
        console.error("02e9300e60914ebc99998ae28a39bd34", error);
        showErrorMessage("Hata oluştu");
      } finally {
        set((state) => {
          state.pageStatus = "ready";
        });
      }
    },
  })),
);

const REQUIRED_MESSAGE = "Zorunlu alan";

const findErrors = (company) => {
  const errors = {};
  if (!company.companyName) errors.companyName = REQUIRED_MESSAGE;
  if (company.eMail && !validateEMail(company.eMail))
    errors.eMail = "Geçersiz e-posta adresi";
  return errors;
};

export default useCompanyUnit;

function postCompany(company) {
  const { appApiAddress, appToken } = useAppInfo.getState();
  return new HttpRequest(appApiAddress, "/metot/create-company")
    .addHeader("authorization", appToken)
    .postAsJson(company);
}

function putCompany(company) {
  const { appApiAddress, appToken } = useAppInfo.getState();
  return new HttpRequest(appApiAddress, "/metot/update-company")
    .addHeader("authorization", appToken)
    .putAsJson(company);
}

function fetchCompany(companyId) {
  const { appApiAddress, appToken } = useAppInfo.getState();
  return new HttpRequest(appApiAddress, `/metot/get-company/${companyId}`)
    .addHeader("authorization", appToken)
    .get();
}

function generateExtraInfoRows(count) {
  const r = [];
  for (let i = 0; i < count; i++) {
    r.push({ infoId: _uniqueId("-"), label: "", value: "" });
  }
  return r;
}
